import React from "react";

export default function Modal({ isCorrect, solution, turn }) {
  return (
    <div className="modal">
      {isCorrect && (
        <div>
          <h1>🏆 Ganaste!</h1>
          <p className="solution">{solution}</p>
          <p>Has encontrado la solución en {turn} intentos :)</p>
          <button
            className="reintentar"
            onClick={() => window.location.reload()}
          >
            Volver a jugar
          </button>
        </div>
      )}
      {!isCorrect && (
        <div>
          <h1>😞 Perdiste!</h1>
          <p className="solution">{solution}</p>
          <p>Mejor suerte la próxima vez :)</p>
          <button
            className="reintentar"
            onClick={() => window.location.reload()}
          >
            Volver a jugar
          </button>
        </div>
      )}
    </div>
  );
}
