import { useEffect, useState } from "react";
import Wordle from "./components/Wordle";

function App() {
  const [solution, setSolution] = useState(null);

  useEffect(() => {
    const json = [
      { id: 1, word: "amaya" },
      { id: 2, word: "baile" },
      { id: 3, word: "barra" },
      { id: 4, word: "bases" },
      { id: 5, word: "cinta" },
      { id: 6, word: "danza" },
      { id: 7, word: "exito" },
      { id: 8, word: "fases" },
      { id: 9, word: "final" },
      { id: 10, word: "forma" },
      { id: 11, word: "ganar" },
      { id: 12, word: "giros" },
      { id: 13, word: "grupo" },
      { id: 14, word: "honor" },
      { id: 15, word: "lucha" },
      { id: 16, word: "manos" },
      { id: 17, word: "mazas" },
      { id: 18, word: "nivel" },
      { id: 19, word: "notas" },
      { id: 20, word: "nudos" },
      { id: 21, word: "orden" },
      { id: 22, word: "pista" },
      { id: 23, word: "punta" },
      { id: 24, word: "ritmo" },
      { id: 25, word: "tapiz" },
      { id: 26, word: "valor" },
      { id: 27, word: "vigor" },
      { id: 28, word: "zonas" },
    ];
    // random int between 0 & 14
    const randomSolution = json[Math.floor(Math.random() * json.length)];
    setSolution(randomSolution.word);
  }, [setSolution]);

  return (
    <div className="App">
      <h1>
        <img src="./logo.png" width="50" height="50" alt="Bitxiak" /> WORDLE
      </h1>
      {solution && <Wordle solution={solution} />}
    </div>
  );
}

export default App;

/* 

data we need to track:
  -- solution
    -- 5 letter string, e.g. 'drain'
  -- past guesses
    -- an array of past guesses
    -- each past guess is an array of letter objects [{}, {}, {}, {}, {}]
    -- each object represents a letter in the guess word {letter: 'a', color: 'yellow'}
  -- current guess
    -- string 'hello'
  -- keypad letters
    -- array of letter objects [{key: 'a', color: 'green'}, {}, {} ...]
  -- number of turns
    -- an integer 0 - 6

game process:
  -- entering words:
    -- user enters a letter & a square is filled with that letter
    -- when a user hits delete it deletes the previous letter
    -- when a user hits enter it submits the word
      -- if all squares are not filled with letters then the word is not submitted
      -- if that word has already been used in a prev guess then the word is not submitted
  -- checking submitted words:
    -- each letter is checked to see if it matches to the solution
    -- each letter is assigned a color based on it's inclusion in the solution
      -- exact matches (correct position in the solution) are green
      -- partial matches (in the solution but not the correct position) are yellow
      -- none-matches (not in the solution at all) are grey
    -- the guess is added the grid with the correct colors
    -- the current guess moves to the next row
    -- the keypad letters are updated (colors)
  -- ending the game:
    -- when the guessed word fully matches the solution
      -- modal to say 'well done'
    -- when the user runs out of guesses
      -- modal to say 'unlucky'

*/
